.poNumber a span {
  font-weight: bold;
  color: #5C7284;
  text-decoration: underline;
}
.messageIcon {
  float: left;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center;
  padding-right: 20px;
  align-self: center;
}
.importError.messageIcon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eGroup 4%3c/title%3e%3cdesc%3eCreated with Sketch.%3c/desc%3e%3cdefs%3e%3c/defs%3e%3cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='notifications/toaster/error' transform='translate(-10.000000, -20.000000)' fill='%23FF6756'%3e%3cg id='Group-4' transform='translate(10.000000, 20.000000)'%3e%3cpath d='M10,1 C15,1 19,5 19,10 C19,15 15,19 10,19 C5,19 1,15 1,10 C1,5 5,1 10,1 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z' id='Fill-1'%3e%3c/path%3e%3cpath d='M15,13.79999 L5,13.79999 C4.800018,13.79999 4.699997,13.70007 4.600006,13.59998 C4.5,13.5 4.5,13.29999 4.600006,13.09998 L9.600006,4.40002 C9.700012,4.29999 9.800018,4.19995 10,4.19995 C10.199997,4.19995 10.300018,4.29993 10.399994,4.40002 L15.399994,13.09998 C15.5,13.19995 15.5,13.40002 15.399994,13.59998 C15.300018,13.70007 15.199997,13.79999 15,13.79999 Z M5.800018,12.90002 L14.199997,12.90002 L10,5.60004 L5.800018,12.90002 Z M10.5,10.09998 L10.5,7.90002 C10.5,7.59998 10.300018,7.40002 10,7.40002 C9.699997,7.40002 9.5,7.59998 9.5,7.90002 L9.5,10.09998 C9.5,10.40002 9.699997,10.59998 10,10.59998 C10.300018,10.59998 10.5,10.29999 10.5,10.09998 Z M10.399994,12 C10.5,11.90002 10.600006,11.70007 10.600006,11.59998 C10.600006,11.49994 10.499985,11.29999 10.399994,11.19995 C10.299988,11.09998 10.100006,10.99994 10,10.99994 C9.800018,10.99994 9.699997,11.09998 9.600006,11.19995 C9.5,11.29993 9.400009,11.49994 9.400009,11.59998 C9.400009,11.70007 9.500031,11.90002 9.600006,12 C9.700012,12.09998 9.900009,12.20007 10,12.20007 C10.199997,12.20007 10.300018,12.09998 10.399994,12 Z' id='Fill-2'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
.importSuccess.messageIcon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eGroup%3c/title%3e%3cdesc%3eCreated with Sketch.%3c/desc%3e%3cdefs%3e%3c/defs%3e%3cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='notifications/toaster/success' transform='translate(-10.000000, -20.000000)' fill='%234AA546'%3e%3cg id='Group' transform='translate(10.000000, 20.000000)'%3e%3cpath d='M10,1 C15,1 19,5 19,10 C19,15 15,19 10,19 C5,19 1,15 1,10 C1,5 5,1 10,1 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z' id='Circle'%3e%3c/path%3e%3cpath d='M8.69167833,14.624955 C8.491685,14.624955 8.29169833,14.5249417 8.19167833,14.4249217 L4.89167167,11.124955 C4.591665,10.8249483 4.591665,10.3249483 4.89167167,10.0249417 C5.19167833,9.724975 5.69167833,9.724975 5.991685,10.0249417 L8.79169833,12.8249483 L15.3916917,6.224975 C15.6916983,5.92500833 16.1916983,5.92500833 16.491685,6.224975 C16.7916983,6.52494167 16.7916983,7.02494167 16.491685,7.32494833 L9.29169833,14.5249417 C8.991685,14.5249017 8.79169833,14.624955 8.69167833,14.624955 Z' id='Checkmark'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
}
.ExcelSummaryModal :global(.content) {
  margin: 0;
  position: static;
}
.ExcelSummaryModal :global(.tab) {
  float: none;
  height: auto;
  overflow: visible;
}
.ExcelSummaryModal :global(.button.disabled) {
  color: #EDEDED !important;
}
.ExcelSummaryModal :global(.ui.table td) {
  padding: 0.78571429em;
}
.ExcelSummaryModal label {
  color: #999999;
}
.ExcelSummaryModal h1,
.ExcelSummaryModal label {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
.ExcelSummaryModal :global(.menu > .message) {
  display: block;
}
.ExcelSummaryModal input {
  color: #323E48;
  line-height: 17px !important;
  font-size: 14px !important;
}
.ExcelSummaryModal :global(.ui.table tr:first-child td) {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
.ExcelSummaryModal :global(.ui.table thead:first-child tr:first-child th) {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
