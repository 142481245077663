@use 'styles/_variables.scss'as *;
@use 'styles/_styleguide.scss'as *;
@use 'styles/_colors.scss'as *;

.RadioSelector {
  .radio {
    font-family: $fontFamily;
    font-size: $smallFontSize;
    margin: 10px;
    label {
      padding-left: 30px !important;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
}