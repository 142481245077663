.SkuDeliveryModal > div:nth-child(2) {
  margin: 0;
}
.SkuDeliveryModal .productContainer {
  margin-bottom: 10px;
  font-family: 'Montserrat', 'Nunito Sans', Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #444444;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
}
.SkuDeliveryModal .productContainer .image {
  width: 66px;
  height: 90px;
  border: 1px solid #EDEDED;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SkuDeliveryModal .productContainer .image span > svg {
  width: 30px;
  height: 50px;
}
.SkuDeliveryModal .productContainer .productInfo {
  height: 75px;
  margin-left: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
}
.SkuDeliveryModal .productContainer .productInfo .productColorInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.SkuDeliveryModal .productContainer .productInfo .productColorInfo .swatch {
  margin-right: 5px;
}
.SkuDeliveryModal .productContainer .productInfo .productColorInfo .swatch > span > span > svg {
  height: 20px;
  width: 20px;
}
.SkuDeliveryModal .productContainer .productInfo .skuDeliveryRange {
  font-weight: 700;
}
.SkuDeliveryModal .skuDeliveryTable {
  font-family: 'Montserrat', 'Nunito Sans', Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #444444;
}
.SkuDeliveryModal .skuDeliveryTable > table > thead > tr:hover {
  background-color: initial !important;
}
.SkuDeliveryModal .skuDeliveryTable > table > tbody > tr {
  background-color: initial !important;
}
.SkuDeliveryModal .skuDeliveryTable > table > tbody > tr > td {
  display: table-cell;
  vertical-align: middle;
}
