.PriceRange {
  display: flex;
}
.PriceRange .rangeMinInputFilter {
  margin: 0 0 0 0.5rem;
}
.PriceRange .rangeMaxInputFilter {
  margin: 0 0.5rem;
}
.PriceRange .PriceRangeButton {
  margin-top: 0;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
.PriceRange .PriceRangeButton span {
  margin-right: 0;
}
.PriceRange .PriceRangeButton span span {
  margin-right: 0;
}
