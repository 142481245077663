@use 'styles/styleguide.scss' as *;
@use 'styles/variables.scss' as *;
@use 'modals/Storefront/StorefrontModal.mixins.module.scss' as *;

.AddImageModal {
  @include storefrontModal;
  .title {
    @include title
  }
  .buttonsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .mediumButton {
      @include mediumButton
    }
    .search {
      @include search
    }
  }
  .loader {
    width: 100%;
  }
  .imagesContainer {
    @include cardContainer;
    .image {
      margin: 15px;
      object-fit: contain;
    }
    .selected {
      border: 1px solid #2d3439;
    }
    .noResults {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 15px;
      font-family: $fontFamily;
      font-size: $fontSize;
      color: #2d3439;
    }
  }
  .paginationContainer {
    @include paginationContainer;
  }
}

.disabledButtons {
  @include disabledButtons;
}
