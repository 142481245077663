.Table {
  width: 100%;
  overflow-x: auto;
}
.Table table {
  border-collapse: collapse;
}
.Table table tbody > tr > td:not(:first-child),
.Table table thead > tr > td:not(:first-child),
.Table table tr > td:not(:first-child) {
  padding-right: 2px;
}
.Table.firstColSticky table > tbody > tr > td:first-child,
.Table.firstColSticky table > thead > tr > td:first-child,
.Table.firstColSticky table > tr > td:first-child {
  position: sticky;
  left: 0;
  top: auto;
  width: 155px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2px;
  z-index: 9;
}
.Table.firstColSticky table > tbody > .Row > td:first-child,
.Table.firstColSticky table > thead > .Row > td:first-child,
.Table.firstColSticky table > .Row > td:first-child {
  font-weight: normal;
  color: #40515D;
  font-size: 12px;
  line-height: 16px;
  height: 29px;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.small,
.Table.firstColSticky table > thead > .Row > td:first-child.small,
.Table.firstColSticky table > .Row > td:first-child.small {
  border-bottom: none;
  height: 17px;
  font-size: 10px;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.medium,
.Table.firstColSticky table > thead > .Row > td:first-child.medium,
.Table.firstColSticky table > .Row > td:first-child.medium {
  height: 23px;
  font-weight: 700;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.big,
.Table.firstColSticky table > thead > .Row > td:first-child.big,
.Table.firstColSticky table > .Row > td:first-child.big {
  height: 46px;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.big td,
.Table.firstColSticky table > thead > .Row > td:first-child.big td,
.Table.firstColSticky table > .Row > td:first-child.big td {
  padding: 10px;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.primary,
.Table.firstColSticky table > thead > .Row > td:first-child.primary,
.Table.firstColSticky table > .Row > td:first-child.primary {
  background-color: #FFFFFF;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.secondary,
.Table.firstColSticky table > thead > .Row > td:first-child.secondary,
.Table.firstColSticky table > .Row > td:first-child.secondary {
  background-color: #EDEDED;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.tertiary,
.Table.firstColSticky table > thead > .Row > td:first-child.tertiary,
.Table.firstColSticky table > .Row > td:first-child.tertiary {
  background-color: #dae8e5;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.primaryBorder,
.Table.firstColSticky table > thead > .Row > td:first-child.primaryBorder,
.Table.firstColSticky table > .Row > td:first-child.primaryBorder {
  border-bottom: 1px solid #CCCCCC;
}
.Table.firstColSticky table > tbody > .Row > td:first-child.secondaryBorder,
.Table.firstColSticky table > thead > .Row > td:first-child.secondaryBorder,
.Table.firstColSticky table > .Row > td:first-child.secondaryBorder {
  border-bottom: 1px solid #EDEDED;
}
.Table.firstColSticky table > tbody > .small > td:first-child,
.Table.firstColSticky table > thead > .small > td:first-child,
.Table.firstColSticky table > .small > td:first-child {
  border-bottom: none;
  font-size: 10px;
  height: 17px;
}
.Table.firstColSticky table > tbody > .medium > td:first-child,
.Table.firstColSticky table > thead > .medium > td:first-child,
.Table.firstColSticky table > .medium > td:first-child {
  font-weight: 700;
  height: 23px;
}
.Table.firstColSticky table > tbody > .big > td:first-child,
.Table.firstColSticky table > thead > .big > td:first-child,
.Table.firstColSticky table > .big > td:first-child {
  height: 47px;
}
.Table.firstColSticky table > tbody > .primary > td:first-child,
.Table.firstColSticky table > thead > .primary > td:first-child,
.Table.firstColSticky table > .primary > td:first-child {
  background-color: #FFFFFF;
}
.Table.firstColSticky table > tbody > .secondary > td:first-child,
.Table.firstColSticky table > thead > .secondary > td:first-child,
.Table.firstColSticky table > .secondary > td:first-child {
  background-color: #EDEDED;
}
.Table.firstColSticky table > tbody > .tertiary > td:first-child,
.Table.firstColSticky table > thead > .tertiary > td:first-child,
.Table.firstColSticky table > .tertiary > td:first-child {
  background-color: #dae8e5;
}
.Table.firstColSticky table > tbody > .primaryBorder > td:first-child,
.Table.firstColSticky table > thead > .primaryBorder > td:first-child,
.Table.firstColSticky table > .primaryBorder > td:first-child {
  border-bottom: 1px solid #CCCCCC;
}
.Table.firstColSticky table > tbody > .secondaryBorder > td:first-child,
.Table.firstColSticky table > thead > .secondaryBorder > td:first-child,
.Table.firstColSticky table > .secondaryBorder > td:first-child {
  border-bottom: 1px solid #EDEDED;
}
.Table.Table.Table.headerSticky table > thead > .primary > td {
  background-color: #FFFFFF;
}
.Table.Table.Table.headerSticky table > thead > .secondary > td {
  background-color: #EDEDED;
}
.Table.Table.Table.headerSticky table > thead > .tertiary > td {
  background-color: #dae8e5;
}
.Table.Table.Table.headerSticky .table > thead > tr > td:first-child {
  border: none;
}
.Table.Table.Table.headerSticky table > thead tr:nth-child(1) td {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  border-bottom: none;
}
.Table.Table.Table.headerSticky table > thead tr:nth-child(1) td:first-child {
  z-index: 11;
}
.Table .Row {
  height: 29px;
  font-weight: normal;
  color: #40515D;
  font-size: 12px;
  line-height: 16px;
}
.Table .Row.small {
  border-bottom: none;
  height: 17px;
  font-size: 10px;
}
.Table .Row.medium {
  height: 23px;
  font-weight: 700;
}
.Table .Row.big {
  height: 46px;
}
.Table .Row.big td {
  padding: 10px;
}
.Table .Row.primary {
  background-color: #FFFFFF;
}
.Table .Row.secondary {
  background-color: #EDEDED;
}
.Table .Row.tertiary {
  background-color: #dae8e5;
}
.Table .Row.primaryBorder {
  border-bottom: 1px solid #CCCCCC;
}
.Table .Row.secondaryBorder {
  border-bottom: 1px solid #EDEDED;
}
