@font-face {
  font-family: 'Verdana Pro W01 Black';
  src: url('assets/fonts/908354/73cf4656-e490-4f0d-b42b-b06af19abcae.woff2')
      format('woff2'),
    url('assets/fonts/908354/2c49497a-bf60-4ce5-998e-106c74bfe40a.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'VerdanaProW01-BlackItal';
  src: url('assets/fonts/908357/c04f1d3a-f8b8-4939-8749-8b82c4c507ca.woff2')
      format('woff2'),
    url('assets/fonts/908357/ae7cd8e0-582a-47b1-9d18-ae4cc4ccd7b6.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Verdana Pro W01 Bold';
  src: url('assets/fonts/908360/a202c9f0-d32a-4f1c-b7ff-99b08d572887.woff2')
      format('woff2'),
    url('assets/fonts/908360/92b89c6c-c77f-4c28-8695-9298b5e774e2.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'VerdanaProW01-BoldItali';
  src: url('assets/fonts/908363/4acfea4b-3c13-40ba-b9fe-a973ce9a02c4.woff2')
      format('woff2'),
    url('assets/fonts/908363/811739ce-95e4-4bb7-8ebc-a46aaf00b9a0.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Verdana Pro W01 Italic';
  src: url('assets/fonts/908396/f4c65174-3f5b-415c-a7c3-8767c2da8428.woff2')
      format('woff2'),
    url('assets/fonts/908396/e66c24c6-5d1a-446e-af99-eea2306a9a02.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Verdana Pro W01 Light';
  src: url('assets/fonts/908399/8062e57f-556b-4da8-900d-1e9aa8114bd2.woff2')
      format('woff2'),
    url('assets/fonts/908399/4b19b0bf-ad32-44ff-b1b7-fad4d66c53c3.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'VerdanaProW01-LightItal';
  src: url('assets/fonts/908402/51c4aabc-ad9d-402e-965c-576b941fb9a0.woff2')
      format('woff2'),
    url('assets/fonts/908402/6e215c46-1dff-42b0-97ea-b98327e2c712.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Verdana Pro W01 Regular';
  src: url('assets/fonts/908405/572d374b-e221-46d8-8ba9-6902ce5c7c94.woff2')
      format('woff2'),
    url('assets/fonts/908405/7537cba6-ac4f-474b-86cb-aaf1542b90e8.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'VerdanaProW01-SemiBold';
  src: url('assets/fonts/908408/9aa57926-38df-4e74-b6b3-fb4dc8a58527.woff2')
      format('woff2'),
    url('assets/fonts/908408/605d2834-6152-4c8b-9b98-299add0ad62b.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'VerdanaProW01-SemiBoldI';
  src: url('assets/fonts/908411/03912099-e2f9-4c47-b1a6-61a577335638.woff2')
      format('woff2'),
    url('assets/fonts/908411/0dfe3c20-2deb-4620-b190-f5834a69e09e.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-Black';
  src: url('assets/fonts/908715/c9095439-39a4-44ea-86f4-4d213eb3af54.woff2')
      format('woff2'),
    url('assets/fonts/908715/a5d15255-f5b4-4cca-808f-211ec0f25ac8.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-BlackItal';
  src: url('assets/fonts/908718/3f3b608e-69dc-410f-8b27-b67aa18c3762.woff2')
      format('woff2'),
    url('assets/fonts/908718/3859825b-bdc4-47f3-af3d-a2ef42d58cfb.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-Bold';
  src: url('assets/fonts/908721/21c9c0f5-9b4f-4ba2-8418-ce3db7bf245d.woff2')
      format('woff2'),
    url('assets/fonts/908721/21c88e6f-2518-4756-ad30-da0b1380a6bd.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-BoldItali';
  src: url('assets/fonts/908724/91f5e104-87e1-4a52-a9e6-1b3a8da5fb64.woff2')
      format('woff2'),
    url('assets/fonts/908724/71bc72b4-e8c3-413f-953d-8dea97140670.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-Italic';
  src: url('assets/fonts/908757/967ae0d1-b075-42cc-8936-bd782ea13e3a.woff2')
      format('woff2'),
    url('assets/fonts/908757/103ac261-2bdc-47c8-b277-4ff41592c169.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-Light';
  src: url('assets/fonts/908760/34e21156-1b01-43b4-b134-06aebfb98f37.woff2')
      format('woff2'),
    url('assets/fonts/908760/edef46b7-56a1-480f-952c-376944431d2c.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-Regular';
  src: url('assets/fonts/908766/97eae991-0db5-44a4-847b-3116c3c23e0e.woff2')
      format('woff2'),
    url('assets/fonts/908766/47123efd-376a-4ca4-a13a-7e6233cf1864.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-SemiBold';
  src: url('assets/fonts/908769/39f132ed-46b2-4873-adba-08f854c0ecba.woff2')
      format('woff2'),
    url('assets/fonts/908769/7e5378b1-28b7-49af-8f8b-501d31a25c3d.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-SemiBoldI';
  src: url('assets/fonts/908772/a9796fad-b245-4d5b-980f-15de399b9a4c.woff2')
      format('woff2'),
    url('assets/fonts/908772/8562bfd4-9442-4187-87a2-18312be7f395.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'GeorgiaProW01-SCLightIt';
  src: url('assets/fonts/908943/b87768b4-c295-420b-b792-fd84a02666b7.woff2')
      format('woff2'),
    url('assets/fonts/908943/93a89708-f3b4-407c-8467-0a0a81302bb0.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Light';
  src: url('assets/fonts/1575548/edd3d818-5039-435b-b823-fba400b72269.woff2')
      format('woff2'),
    url('assets/fonts/1575548/91790d64-5305-4c34-aea7-68067aaacab5.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Light Italic';
  src: url('assets/fonts/1575554/7ab4d9ec-e400-4875-ad48-c6bf369c3433.woff2')
      format('woff2'),
    url('assets/fonts/1575554/89e99dfe-ca93-4b73-9a20-345a9eaa0593.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Roman';
  src: url('assets/fonts/1575560/c86216ba-b6f5-44bf-8bc5-31f2efb26cd5.woff2')
      format('woff2'),
    url('assets/fonts/1575560/b127c2c0-82cc-493d-a525-edc3aea7359d.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Italic';
  src: url('assets/fonts/1575570/508002b5-b8ec-482a-a8bb-bcb173e38d50.woff2')
      format('woff2'),
    url('assets/fonts/1575570/34b0d84c-b10d-42a8-a4c8-600827f1852d.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Medium';
  src: url('assets/fonts/1575576/b77309e0-acec-466f-9cab-d32e41a7b02d.woff2')
      format('woff2'),
    url('assets/fonts/1575576/9401fcb1-32f9-4243-a121-39b73b838471.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Medium Italic';
  src: url('assets/fonts/1575582/ee72961b-295e-4a15-ac78-7a53d7f05efe.woff2')
      format('woff2'),
    url('assets/fonts/1575582/16520eb5-22b3-459e-b302-1db12c779716.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Bold';
  src: url('assets/fonts/1575588/134f83cb-28c7-40e6-a4e8-0e9db149329e.woff2')
      format('woff2'),
    url('assets/fonts/1575588/1a90cca9-0fcb-4446-9b27-9bb548420e63.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Bold Italic';
  src: url('assets/fonts/1575594/2693154f-62cd-4623-8655-c0425cdc0c2a.woff2')
      format('woff2'),
    url('assets/fonts/1575594/d091fd32-6694-4d7c-a3e1-de57eec24153.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Black';
  src: url('assets/fonts/1575600/539e4328-2dad-4b42-9515-2236d7048de2.woff2')
      format('woff2'),
    url('assets/fonts/1575600/21f265e0-3f97-4e64-b2af-1b27b0ec3388.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Palatino LT W01 Black Italic';
  src: url('assets/fonts/1575606/c7674039-336f-4844-aad7-c8095b5ae148.woff2')
      format('woff2'),
    url('assets/fonts/1575606/e1966676-a7e5-403a-bcdb-ecb72a819f6c.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 Light';
  src: url('assets/fonts/5608453/56666c82-7fa2-4d0e-a97f-f83a8eab187f.woff2')
      format('woff2'),
    url('assets/fonts/5608453/acb14714-9317-4362-a596-df0c4a5ce627.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 Light Italic';
  src: url('assets/fonts/5608462/a7457fe1-9190-4e89-b394-4b2e342460eb.woff2')
      format('woff2'),
    url('assets/fonts/5608462/01c891bd-35cb-4c07-b4bb-ea3431b1907c.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 Regular';
  src: url('assets/fonts/5608471/1c9448fe-7605-4269-9ad3-d8c2dbe01ebc.woff2')
      format('woff2'),
    url('assets/fonts/5608471/413af1a3-8b25-474c-9bb6-8d89bb907596.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 Italic';
  src: url('assets/fonts/5608483/b7b3c05f-ebe7-4a3b-8309-6a90cd4ba485.woff2')
      format('woff2'),
    url('assets/fonts/5608483/a711e122-8217-4abf-9182-77dc703c5bf8.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 Medium';
  src: url('assets/fonts/5608495/6375bbc6-1416-4fe0-82e8-cb5016730894.woff2')
      format('woff2'),
    url('assets/fonts/5608495/2d02673c-1266-421e-8a31-241190c60831.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 Medium Italic';
  src: url('assets/fonts/5608504/f5998358-ff29-4f6b-8e27-ef9f101252cf.woff2')
      format('woff2'),
    url('assets/fonts/5608504/43ef312d-ae60-45e2-8a2f-860e6c0f7d16.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 Bold';
  src: url('assets/fonts/5608513/3dd0117f-6b73-42bb-85da-c6e4c0440050.woff2')
      format('woff2'),
    url('assets/fonts/5608513/d3acd276-9323-4d81-a9a1-5770c58108c5.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 Bold Italic';
  src: url('assets/fonts/5608525/4c1b8b55-58d7-4f22-a608-c61d5774a28a.woff2')
      format('woff2'),
    url('assets/fonts/5608525/edcddada-9b91-4110-9aff-bcdfd01c3e10.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 ExtraBold';
  src: url('assets/fonts/5608537/3329b370-9541-4b13-b468-03433afa4816.woff2')
      format('woff2'),
    url('assets/fonts/5608537/bc4e3353-2633-48ea-8653-ff09d7fac509.woff')
      format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Arial MT W01 ExtraBold Italic';
  src: url('assets/fonts/5608549/b8fa10de-fca1-4e49-af76-17da2cd5f370.woff2')
      format('woff2'),
    url('assets/fonts/5608549/627dccbe-373f-409d-9869-c34de65c1182.woff')
      format('woff');
  font-display: swap;
}

#root {
  position: relative;
}
#root-popups {
  position: relative;
  z-index: 4000;
}
body
  > div:not(#root):not(#root-popups):not([style*='z-index']):not(.modals):not(#cboxOverlay):not(#colorbox):not([role='presentation'][id$='_modal']):not(.modal-backdrop):not([id^='flatfile-']) {
  z-index: 0;
}
/* This styling is needed for the designer elements of Pendo to be visible */
body > #pendo-mousemove-cover,
body > #pendo-designer-container,
body > #pendo-designer-iframe,
body > #pendo-designer-communication-iframe,
body > #pendo-action-bar-container,
body > #pendo-action-bar-iframe,
body > #pendo-display-none-override,
body > #pendo-draggable-handle,
body > #pendo-guide-container,
body > #pendo-loading-overlay {
  z-index: 3000000 !important;
}
body > #pendo-base {
  z-index: 20000 !important;
}
body > .bg.layout-block {
  position: relative;
}
body > #cboxOverlay,
div.joor-react ~ #cboxOverlay {
  margin-top: 0 !important;
  z-index: 1;
}
body > #colorbox {
  z-index: 3100;
}
.modal-backdrop.fade.in {
  display: none;
}
.modal.fade[role='dialog'] {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
}
body > .zendesk-messaging {
  display: block !important;
}
