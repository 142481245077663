/*******************************
         Site Settings
*******************************/

@use './_colors.scss' as *;

/*-------------------
       Fonts
--------------------*/
$fontName: 'Nunito Sans';
$fontMontserrat: 'Montserrat';
$fontSmoothing: antialiased;

$fontFamily: $fontMontserrat, $fontName, Helvetica, Arial, sans-serif;
$headerFont: $fontName, Helvetica, Arial, sans-serif;
$pageFont: $fontName, Helvetica, Arial, sans-serif;
$interactiveFont: 'Nunito Sans';

$googleFontName: $fontName;
$importGoogleFonts: true;
$googleFontSizes: '200,400,700';
$googleSubset: 'latin';

$googleProtocol: 'https://';
$googleFontRequest: '${googleFontName}:${googleFontSizes}&subset=${googleSubset}';

/*-------------------
      Base Sizes
--------------------*/

/* This is the single variable that controls them all */
$emSize: 14px;

/* The size of page text  */
$fontSize: 14px;

/*-------------------
    Other Font Sizes
--------------------*/

$smallFontSize: 12px;
$largerFontSize: 21px;

/*-------------------
      Font Weights
--------------------*/

$thin: 200;
$regular: 400;
$medium: 500; // only available on Monsterrat
$semibold: 600;
$bold: 700;

/*-------------------
     Transitions
--------------------*/

$defaultDuration: 0.1s;
$defaultEasing: ease;

/*-------------------
     Breakpoints
--------------------*/
// Legacy breakpoints
$mobileBreakpoint: 320px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1200px;
$widescreenMonitorBreakpoint: 1920px;

// New UX defined breakpoints
$smallBreakpointWidth: 1024px;
$mediumBreakpointWidth: 1366px;
$largeBreakpointWidth: 1920px;

// UX defined column widths
$smallPageMargins: 19px;
$smallGutterWidth: 10px;
$smallBaseColumnWidth: 73px;
$smallColumnWidth: $smallBaseColumnWidth + $smallGutterWidth;

$mediumPageMargins: 33px;
$mediumGutterWidth: 20px;
$mediumBaseColumnWidth: 90px;
$mediumColumnWidth: $mediumBaseColumnWidth + $mediumGutterWidth;

$largePageMargins: 90px;
$largeGutterWidth: 20px;
$largeBaseColumnWidth: 90px;
$largeColumnWidth: $largeBaseColumnWidth + $largeGutterWidth;

/*-------------------
      Site Colors
--------------------*/

/*--- Old Colors  ---*/

$black: #000000;
$blue: #74c3e1;
$blueFaded: rgba(116, 195, 225, 0.2);
$blueIndicator: #3b72ab;
$borderFadedBlue: rgba(46, 91, 255, 0.08);
$darkBlue: #40515d;
$darkGold: #796833;
$darkGray: #444444;
$darkTeal: #526264;
$deepTeal: #343f40;
$fadedBlue: rgba(104, 132, 166, 0.25);
$fadedTeal: rgba(66, 138, 126, 0.15);
$fadedTeal2: rgba(66, 138, 126, 0.5);
$gold: #ad9549;
$gray: #999999;
$grayCart: #333639;
$grayDarkFaded: rgba(0, 0, 0, 0.4);
$grayFaded: rgba(239, 239, 240, 0.8);
$green: #4ead47;
$greenFaded: rgba(78, 173, 71, 0.2);
$lightBlack: #545454;
$lightBlue: #54c8ff;
$lighterBlue: #e9f4f6;
$lighterGray: #ededed;
$lightestGray: #f8f8f8;
$lightGray: #cccccc;
$lightGreen: #d9e8e5;
$lightNavy: #6884a6;
$lightRed: #ff6756;
$lightTeal: #61aaa6;
$lightViolet: #a4608b;
$maroon: #8f5171;
$maroonFaded: rgba(143, 81, 113, 0.2);
$mediumGray: #666666;
$mediumGreen: #428a7e;
$mediumGreenFaded: rgba(66, 138, 126, 0.15);
$paleTeal: #f5f9f8;
$primaryenYellow: #e6a616;
$primaryFaded: rgba(173, 149, 73, 0.2);
$purple: #5e599c;
$purpleFaded: rgba(94, 89, 156, 0.2);
$red: #e7251a;
$requiredField: $red;
$rosePink: #a6567b;
$successGreen: #4aa546;
$superFadedTeal: rgba(66, 138, 126, 0.05);
$superLightGray: #e2e0df;
$superLightTeal3  : rgb(218,232,229);
$twGray300: #d1d5db;
$white: #ffffff;
$yellow: #ffcb05;
$yellowIndicator: #f8c723;

/*-------------------
    Brand Colors
--------------------*/

$primaryColor: $primary;
$secondaryColor: $darkTeal;

$lightPrimaryColor: $lightBlue;
$lightSecondaryColor: $lightBlack;

/*-------------------
   Image dimensions
--------------------*/

@mixin image220x300() {
  width: 220px;
  height: 300px;
}

@mixin thumbnailSize() {
  width: 56px;
  height: 75px;
}

@mixin missingThumbnailSize() {
  @include thumbnailSize();
  svg {
    width: 30px;
    height: 24px;
  }
}

/*-------------------
   JOOR common dimensions
--------------------*/
$navBarHeight: 78px;
