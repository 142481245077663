.numberInput input {
  font-size: 12px !important;
  text-align: right !important;
  height: 20px;
  width: 45px;
  padding: 2px;
  border: 1px solid #CCCCCC !important;
  background-color: #F8F8F8 !important;
}
.numberInput input:hover {
  border: 1px solid #40515D !important;
}
.numberInput input:focus {
  border-color: #40515D !important;
  background-color: rgba(173, 149, 73, 0.2) !important;
}
.numberInput input:focus::selection {
  background: transparent;
}
.numberInput input:disabled:hover {
  border: 1px solid #CCCCCC !important;
}
.numberInput.invalid input {
  border: 1px solid #E7251A !important;
}
.column {
  vertical-align: bottom;
  position: relative;
  font-weight: 700;
  text-transform: capitalize;
}
.column.commonColumns {
  width: 45px;
}
.column .casepacksHeader {
  color: #5E599C;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.column .unitsAvailable {
  font-weight: 400;
}
.column .unitsAvailable.invalid {
  color: #E7251A;
}
.singleColumn {
  max-width: 338px;
}
.multipleColumns {
  max-width: 60px;
}
.deliveryDate {
  margin-left: 20px;
}
.copyPasteIcon {
  cursor: pointer;
}
