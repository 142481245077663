.OrdersSelectionFilters {
  margin-bottom: 80px !important;
}
.OrdersSelectionFilters .searchFilter {
  margin-bottom: 10px;
}
.OrdersSelectionFilters .datePicker {
  margin-left: 15px;
}
.OrdersSelectionFilters .pillContainer {
  border-top: 1px solid #CCCCCC;
  padding: 10px 0 15px;
}
.OrdersSelectionFilters .pillContainer .header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.9em;
  color: #40515D;
}
.OrdersSelectionFilters .pillContainer .header .title {
  font-weight: 600;
}
.OrdersSelectionFilters .pillContainer .header .clearAll {
  border: none;
  padding: 0;
  color: #5C7284;
  font: inherit;
  font-weight: 100;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}
