.Cell {
  min-width: 45px;
  white-space: nowrap;
}
.Cell.fullWidth {
  display: table-cell;
  width: 100%;
}
.Cell.withBorder {
  border-right: 1px solid #CCCCCC;
}
.Cell.highlighted {
  background-color: rgba(66, 138, 126, 0.05);
}
.Cell.right {
  text-align: right;
}
.Cell.right div {
  text-align: right;
}
.Cell.left {
  text-align: left;
}
.Cell.left div {
  text-align: left;
}
.Cell.center {
  text-align: center;
}
.Cell.center div {
  text-align: center;
}
