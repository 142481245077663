.ProductDetailModal {
  overflow-x: hidden !important;
  width: 900px !important;
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
.ProductDetailModal :global(.content) {
  margin: 0;
  position: static;
}
.ProductDetailModal .detailContainer {
  display: flex;
}
.ProductDetailModal .detailContainer .imagesContainer {
  width: 50%;
  display: flex;
}
.ProductDetailModal .detailContainer .imagesContainer .carouselContainer {
  max-height: 450px;
  overflow-y: auto;
  padding-right: 15px;
}
.ProductDetailModal .detailContainer .imagesContainer .carouselContainer .carousel {
  display: flex;
  flex-direction: column;
}
.ProductDetailModal .detailContainer .imagesContainer .carouselContainer .carousel .image {
  margin-bottom: 10px;
  min-width: 56px;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}
.ProductDetailModal .detailContainer .imagesContainer .carouselContainer .carousel .image span > svg {
  width: 30px;
  height: 50px;
}
.ProductDetailModal .detailContainer .imagesContainer .carouselContainer .carousel .image:hover,
.ProductDetailModal .detailContainer .imagesContainer .carouselContainer .carousel .selected {
  border: 1px solid #5C7284;
}
.ProductDetailModal .detailContainer .imagesContainer .largeMediaFile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 330px;
  height: 450px;
}
.ProductDetailModal .detailContainer .imagesContainer .largeMediaFile iframe {
  border: none;
}
.ProductDetailModal .detailContainer .imagesContainer .largeMediaFile:not(.video) span > span > svg {
  width: 126px;
  height: 99px;
}
.ProductDetailModal .detailContainer .imagesContainer .largeMediaFile .imageMagnify {
  height: 450px !important;
  width: 330px !important;
  z-index: 1;
}
.ProductDetailModal .detailContainer .imagesContainer .largeMediaFile .imageSmallMagnify {
  object-fit: contain !important;
}
.ProductDetailModal .detailContainer .imagesContainer .largeMediaFile .badge {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 1;
  transition: opacity 500ms;
}
.ProductDetailModal .detailContainer .imagesContainer .largeMediaFile:hover .badge {
  opacity: 0;
}
.ProductDetailModal .detailContainer .imagesContainer .noMedia {
  border: 1px solid #CCCCCC;
}
.ProductDetailModal .detailContainer .imagesContainer .noMedia .noMediaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ProductDetailModal .detailContainer .infoContainer {
  width: 50%;
  word-break: break-word;
}
.ProductDetailModal .detailContainer .infoContainer .headerContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  font-weight: normal;
  color: #40515D;
  font-size: 18px;
  line-height: 24px;
}
.ProductDetailModal .detailContainer .infoContainer .headerContainer .productName {
  font-weight: bold;
  color: #40515D;
  font-size: 18px;
  line-height: 24px;
}
.ProductDetailModal .detailContainer .infoContainer .infoRow {
  display: flex;
  border-bottom: 1px solid #E2E0DF;
  padding-bottom: 5px;
  padding-top: 5px;
}
.ProductDetailModal .detailContainer .infoContainer .infoRow .infoContent {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-weight: normal;
  color: #40515D;
  font-size: 14px;
  line-height: 18px;
}
.ProductDetailModal .detailContainer .infoContainer .infoRow .infoContent .value {
  font-weight: 700;
}
.ProductDetailModal .detailContainer .infoContainer .infoRow .infoContent.fullWidth {
  width: 100%;
}
.ProductDetailModal .detailContainer .infoContainer .infoRow:nth-last-child(2) {
  border-bottom: none;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer {
  flex-direction: column;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues {
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .smallSwatch {
  margin-right: 4px;
  height: 24px;
  padding: 1px;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .selected {
  border: 1px solid #5C7284;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo {
  display: flex;
  flex-wrap: nowrap;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .bigSwatch {
  height: 40px;
  margin-right: 4px;
  padding: 1px;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .bigSwatch svg {
  width: 35px;
  height: 35px;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .selected {
  border: 1px solid #5C7284;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .skuDeliveryInfo {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .skuDeliveryInfo > div:nth-child(2) > div {
  border: none;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .skuDeliveryInfo > div:nth-child(2) > div > div:first-child {
  padding: 1px 0 1px 0;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .skuDeliveryInfo > div:nth-child(2) > div > div:first-child > div > div {
  font-size: 10px;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .skuDeliveryInfo > div:nth-child(2) > div > div:first-child > div > div::before {
  top: 0;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .skuDeliveryInfo .colorSkuDelivery {
  font-size: 10px;
  padding: 0 0 0 24px;
  border: none;
  min-height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorVariantInfo .skuDeliveryInfo .colorSkuDelivery:hover {
  background-color: initial;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .variantValues .colorSkuDeliveryAlignment {
  align-items: center;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .columnAlignment {
  flex-direction: column;
}
.ProductDetailModal .detailContainer .infoContainer .variantContainer .rowAlignment {
  display: contents;
}
.ProductDetailModal .detailContainer .infoContainer .plusIcon {
  margin-right: 10px;
}
.ProductDetailModal .detailContainer .infoContainer .plusIcon svg {
  width: 11px;
  height: 11px;
}
.ProductDetailModal .detailContainer .infoContainer .sizeVariantDetailAccordion {
  max-width: 100%;
}
.ProductDetailModal .detailContainer .infoContainer .sizeVariantDetailAccordion .header {
  padding: 10px 10px 10px 0px;
  font-size: 14px;
  line-height: 19px;
}
.ProductDetailModal .detailContainer .infoContainer .sizeVariantDetailAccordion .table {
  min-width: 100%;
  font-size: 12px;
  text-align: left;
  margin-bottom: 15px;
}
.ProductDetailModal .detailContainer .infoContainer .sizeVariantDetailAccordion .table .row {
  border-bottom: 1px solid #E2E0DF;
}
.ProductDetailModal .detailContainer .infoContainer .sizeVariantDetailAccordion .table .row .sizeDescription,
.ProductDetailModal .detailContainer .infoContainer .sizeVariantDetailAccordion .table .row .sizeValue {
  font-weight: 700;
  vertical-align: middle;
}
.ProductDetailModal .detailContainer .infoContainer .sizeVariantDetailAccordion .table .row .sizeValue {
  width: 100px;
}
.ProductDetailModal .detailContainer .infoContainer .detailAccordion {
  border-top: solid 2px rgba(66, 138, 126, 0.5);
  border-bottom: solid 2px rgba(66, 138, 126, 0.5);
}
.ProductDetailModal .detailContainer .infoContainer .detailAccordion .detailHeader {
  padding: 10px 0px 10px 0px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
.ProductDetailModal .detailContainer .infoContainer .detailAccordion .traitValuesContainer {
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}
.ProductDetailModal .detailContainer .infoContainer .detailAccordion .traitValuesContainer .traitContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-weight: normal;
  color: #40515D;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid #E2E0DF;
  padding-top: 7px;
  padding-bottom: 6px;
  white-space: pre-wrap;
}
.ProductDetailModal .detailContainer .infoContainer .detailAccordion .traitValuesContainer .traitContainer .value {
  font-weight: 700;
  padding-top: 3px;
}
.ProductDetailModal .detailContainer .infoContainer .detailAccordion .traitValuesContainer .traitContainer:last-child,
.ProductDetailModal .detailContainer .infoContainer .detailAccordion .traitValuesContainer .traitContainer:nth-last-child(2) {
  border-bottom: none;
}
.ProductDetailModal .detailContainer .infoContainer .detailAccordion .traitValuesContainer .description {
  width: 100%;
  border-top: 1px solid #E2E0DF;
}
.ProductDetailModal .doorsContainer {
  padding: 20px 0 0 0;
  font-weight: bold;
  color: #40515D !important;
  font-size: 14px;
  line-height: 18px;
}
.ProductDetailModal .dropdownsContainer {
  position: relative;
  margin: 10px 0 0;
  padding: 10px 0 0;
  border-top: 1px solid #CCCCCC;
  z-index: 2;
  display: flex;
  align-items: center;
}
.ProductDetailModal .dropdownsContainer .dropdownContainer {
  width: 200px;
  margin-right: 20px;
}
.ProductDetailModal .dropdownsContainer .dropdownContainer .dropdownLabelContainer {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.ProductDetailModal .dropdownsContainer .dropdownContainer .dropdownLabelContainer .dropdownLabel {
  font-weight: normal;
  color: #40515D;
  font-size: 14px;
  line-height: 18px;
  margin-right: 5px;
}
.ProductDetailModal .dropdownsContainer .dropdownContainer .dropdownLabelContainer .dropdownIcon {
  height: 16px;
}
.ProductDetailModal .dropdownsContainer .dropdownContainer .dropdownLabelContainer .dropdownIcon svg {
  width: 16px;
  height: 16px;
}
.ProductDetailModal .dropdownsContainer .dropdownContainer .dropdownLabelContainer.labelWithoutIcon {
  margin-bottom: 1px;
}
.ProductDetailModal .dropdownsContainer .dropdownContainer .dropdown {
  color: #40515D;
  font-size: 14px;
}
.ProductDetailModal .dropdownsContainer .dropdownContainer .dropdown > div:first-child {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tooltipSize {
  width: 150px;
}
.tooltipContent {
  font-size: 14px;
}
