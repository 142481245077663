.ProductDetailBulkTable {
  overflow-x: auto;
}
.ProductDetailBulkTable .table {
  max-height: 400px;
}
.ProductDetailBulkTable .table table > thead > tr > td {
  padding-bottom: 0 !important;
}
.ProductDetailBulkTable .table table > tbody > tr > td {
  vertical-align: middle;
}
.ProductDetailBulkTable .table table > tbody > tr > td:first-child,
.ProductDetailBulkTable .table table > thead > tr > td:first-child,
.ProductDetailBulkTable .table table > tr > td:first-child {
  width: 210px;
}
.ProductDetailBulkTable .table > table {
  min-width: 618px;
}
.ProductDetailBulkTable .table .numberInput input {
  font-size: 12px !important;
  text-align: right !important;
  height: 20px;
  width: 45px;
  padding: 2px;
  border: 1px solid #CCCCCC !important;
  background-color: #F8F8F8 !important;
}
.ProductDetailBulkTable .table .numberInput input:hover {
  border: 1px solid #40515D !important;
}
.ProductDetailBulkTable .table .numberInput input:focus {
  border-color: #40515D !important;
  background-color: rgba(173, 149, 73, 0.2) !important;
}
.ProductDetailBulkTable .table .numberInput input:focus::selection {
  background: transparent;
}
.ProductDetailBulkTable .table .numberInput input:disabled:hover {
  border: 1px solid #CCCCCC !important;
}
.ProductDetailBulkTable .table .numberInput.invalid input {
  border: 1px solid #E7251A !important;
}
