.catalogFilters {
  height: 60px;
  margin-top: -10px !important;
}
.catalogFilters > div:not(.noPadding) {
  margin: 10px 0;
}
.catalogFilters .groupBy,
.catalogFilters .sortBy {
  max-width: 200px;
  height: 40px;
}
.catalogFilters .groupBy > div:first-child,
.catalogFilters .sortBy > div:first-child {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.catalogFilters .noPadding {
  padding: 0;
}
.catalogFilters .filterListContainer {
  padding: 10px;
  background-color: #EDEDED;
}
.catalogFilters .filterListContainer .filterDropdown {
  width: 100%;
  max-width: 220px;
}
.catalogFilters .filterListContainer .filterDropdown div {
  white-space: nowrap;
}
.catalogFilters .filterListContainer .dynamicFilter {
  height: 40px;
}
.catalogFilters .filterListContainer .dynamicFilter > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.catalogFilters .filterListContainer .disabledFilterItem {
  color: #444444;
  cursor: initial;
}
.catalogFilters .filterListContainer .filterList {
  width: 100%;
}
.catalogFilters .filterListContainer .filterList input {
  width: 100%;
  min-width: 200px;
}
.catalogFilters .searchContainer {
  display: flex;
  justify-content: flex-end;
  height: 40px;
}
.catalogFilters .searchContainer > div {
  max-width: 310px;
  flex-grow: 1;
}
@media only screen and (max-width: 1366px) {
  .catalogFilters .filterListContainer {
    max-width: 730px;
  }
  .catalogFilters .filterListContainer .filterDropdown {
    max-width: 155px;
  }
  .catalogFilters .filterListContainer .filterDropdown > div:first-child {
    height: 18px;
  }
  .catalogFilters .filterListContainer .filterDropdown > div:first-child > div:first-child {
    max-width: 120%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .catalogFilters .filterListContainer .dynamicFilter {
    max-width: 155px;
  }
  .catalogFilters .filterListContainer .dynamicFilter > div {
    max-width: 120%;
  }
  .catalogFilters .filterListContainer .dynamicFilter > div > div:first-child {
    width: 155px;
  }
}
@media only screen and (min-width: 1366px) {
  .catalogFilters .filterListContainer .dynamicFilter > div {
    width: 220px;
  }
}
