.indicatorCell {
  display: flex;
  flex-flow: column;
  height: 42px !important;
}
.indicator {
  width: 8px;
  flex-grow: 1;
}
.red {
  background: linear-gradient(to right, #FF6756 0%, #FF6756 37.5%, transparent 37.5%, transparent 100%);
}
.yellow {
  background: linear-gradient(to right, #F8C723 0%, #F8C723 37.5%, transparent 37.5%, transparent 100%);
}
.blue {
  background: linear-gradient(to right, #3B72AB 0%, #3B72AB 37.5%, transparent 37.5%, transparent 100%);
}
