.Table {
  position: relative;
  overflow: hidden;
  font-size: 12px;
}
.Table:hover {
  overflow: auto;
}
.Table.fullWidth table:global(.ui.table) {
  width: 100% !important;
}
.Table .sticky > tr th {
  border-bottom: none;
}
.Table .sticky > tr:first-child th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}
.Table .sticky > tr:first-child th:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  pointer-events: none;
}
.Table .highlightedRow {
  background-color: #CDD4DA;
}
.Table.sortable thead th {
  cursor: pointer;
}
.Table.sortable thead th:hover {
  background: #F8F8F8;
}
.Table.compact thead > tr > th {
  line-height: 15px;
  padding: 7px 0 7px 12px;
}
.Table.compact td {
  padding: 0 0 0 12px;
}
.Table .noResult {
  font-weight: bold;
  color: #40515D !important;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding-top: 20px;
}
.Table table:global(.ui.table) thead th.expandableRow {
  cursor: pointer;
}
:global .ui.table {
  border-collapse: collapse;
}
:global .ui.table.borderless {
  border: none;
}
.noPaddingLeft {
  padding-left: 0px !important;
}
