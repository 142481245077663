@use 'styles/_variables.scss'as *;
@use 'styles/_styleguide.scss'as *;
@use 'styles/_colors.scss'as *;

.StorefrontSaveChanges {
  border-radius: 15px !important;
  color: #2d3439 !important;

  .title {
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: $regular;
    margin: 0 0 15px;
  }

  div:nth-child(3) {
    >div:hover {
      border: none !important;
    }

    button {
      font-family: $fontFamily !important;
      font-size: $smallFontSize !important;
      background-color: $white !important;
      border: 1px solid #2d3439;
      border-radius: 5px;
      padding: 2px 10px;
      cursor: pointer;
      color: #2d3439 !important;
      margin-right: 20px;
      text-align: center;
    }

    >div:nth-child(3) {
      button {
        font-family: $fontFamily !important;
        font-size: $smallFontSize !important;
        background-color: #5c7284 !important;
        border: 1px solid #2d3439;
        border-radius: 5px !important;
        padding: 2px 10px;
        cursor: pointer;
        color: white !important;
        margin-right: 20px;
        text-align: center;
      }
    }
  }
}

.StorefrontSaveChanges {
  &.additionalClass {
    margin-top: 70px !important;
  }
}