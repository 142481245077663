.filterPanelContainer {
  height: 60px;
  margin-bottom: 8px;
}
.filterPanelContainer .filterPanelRow {
  display: flex;
}
.filterPanelContainer .filterPanelRow .dateFilterColumn {
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
}
.filterPanelContainer .filterPanelRow .dateFilterColumn .dateFilter {
  display: flex;
}
.filterPanelContainer .filterPanelRow .dateFilterColumn .dateFilter .title {
  margin-top: 8px;
  margin-right: 8px;
  font-weight: 700;
}
.filterPanelContainer .filterPanelRow .dateFilterColumn .dateFilter .subtitle {
  width: 150px;
  font-size: 12px;
}
.filterPanelContainer .filterPanelRow .dateFilterColumn .dateFilter .datePicker {
  width: 500px;
  z-index: 2;
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .filterPanelContainer .filterPanelRow .dateFilterColumn .dateFilter .datePicker {
    width: 390px;
  }
}
@media (min-width: 1024px) and (max-width: 1365px) {
  .filterPanelContainer .filterPanelRow .dateFilterColumn .dateFilter .datePicker {
    width: 330px;
  }
}
.filterPanelContainer .filterPanelRow .dateFilterColumn .dateFilter .datePicker span > input {
  padding: 11px 10px 11px 13px;
}
