.CancelStyleFilters {
  margin-bottom: 80px !important;
}
.CancelStyleFilters .datePicker {
  margin-left: 15px;
}
.CancelStyleFilters .filterWrapper {
  padding: 10px 0;
}
.CancelStyleFilters .pillContainer {
  border-top: 1px solid #CCCCCC;
  padding: 10px 0 15px;
}
.CancelStyleFilters .pillContainer .header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.9em;
  color: #40515D;
}
.CancelStyleFilters .pillContainer .header .title {
  font-weight: 600;
}
.CancelStyleFilters .pillContainer .header .clearAll {
  border: none;
  padding: 0;
  color: #5C7284;
  font: inherit;
  font-weight: 100;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
}
.CancelStyleFilters .pillContainer .header .disabled {
  color: #CCCCCC;
  font-weight: normal;
  cursor: default;
}
