@use 'styles/_variables.scss'as *;
@use 'styles/_colors.scss'as *;

@mixin lightButton {
  font-family: $fontFamily;
  font-size: $smallFontSize;
  background-color: $white;
  border: 1px solid #2d3439;
  border-radius: 5px;
  padding: 2px 10px;
  height: 30px;
  cursor: pointer;
  color: #2d3439;
  margin-right: 20px;
  text-align: center;

  &:hover {
    border: 1px solid #323e48;
    background-color: $white;
    color: #2d3439;
    transform: none;
  }
}

@mixin darkButton {
  background-color: #323e48;
  border: none;
  border-radius: 5px;
  color: $white;
  cursor: pointer;
  font-family: $fontFamily;
  font-size: $smallFontSize;
  height: 30px;
  padding: 2px 10px;
  text-align: left;

}

@mixin storefrontModal {
  border-radius: 15px !important;
  color: #2d3439 !important;

  // Remove top divider
  >div:first-child {
    border: none !important;
  }

  >div:nth-child(2) {
    padding: 0 !important;
  }

  // Style modal buttons
  >div:last-child {
    display: flex;
    justify-content: flex-end;

    div:first-child {
      width: 100px;
      border: none;

      &:hover {
        background-color: initial;
        border: none;
      }

      div {
        width: 100px;

        button {
          @include lightButton;
        }
      }
    }

    div:last-child {
      width: 200px;
      border: none;

      &:hover {
        background-color: initial;
        border: none;
      }

      div button {
        @include darkButton;

        &:hover {
          background-color: #323e48;
        }
      }
    }
  }
}

@mixin title {
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: $regular;
  margin: 0 0 15px;
}

@mixin storefrontModalButton {
  button {
    @include darkButton;

    a {
      color: $white;
      text-decoration: none;

      &:hover {
        color: $white;
      }
    }
  }
}

@mixin mediumButton {
  width: 200px;
  @include storefrontModalButton
}

@mixin search {
  display: flex;
  height: 30px;
  width: 640px;
  max-width: initial;
  margin-right: 20px;
  border-radius: 20px;

  &:hover>span {
    background-color: #5c7284;
  }

  >input {
    border-radius: 5px 0 0 5px;
    width: 600px;
    min-width: 600px;
  }

  >span>span {
    height: 30px;
    width: 39px;
    background-color: #8c9ba8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0 5px 5px 0;

    >svg {
      height: 20px;
      width: 20px;
      position: absolute;
      top: 5px;
      left: 8px;

      path {
        fill: $white;
      }
    }
  }
}

@mixin loader {
  width: 100%;
  height: 100%;
}

@mixin card {
  margin: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  width: 140px;

  span {
    width: 100%;
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  img {
    object-fit: contain;
    width: 100%;
  }
}

@mixin selectedCard {
  border: 1px solid #2d3439;

}

@mixin cardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #cdd4da;
  border-radius: 15px;
  min-height: 512px;

}

@mixin paginationContainer {
  display: flex;
  justify-content: flex-end;
}

@mixin disabledButtons {
  >div:last-child>div:last-child {
    border: none;

    div button {
      background-color: $lightGray;
    }
  }
}
