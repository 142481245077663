@use 'styles/_variables.scss'as *;
@use 'styles/_styleguide.scss'as *;
@use 'styles/_colors.scss'as *;
@use 'src/features/Storefront/StorefrontMixins.module.scss'as *;

.AddImageTooltip {
  &::before {
    display: none;
  }
  .title {
    @include tooltipTitle;
    margin: 0 0 25px 0;
  }
  .subtitle {
    @include tooltipSubtitle;
    margin: 25px 0 6px;
  }
  .urlInput>input {
    border: 1px solid #2d3439;
    border-radius: 5px;
    padding: 2px 10px;
    height: 25px;
    max-width: calc(400px - 23px);
  }
  .cropLabel {
    display: flex;
    align-items: center;
    >span {
      height: 21px;
      font-size: $smallFontSize;
      font-weight: $regular;
      margin-right: 5px;
    }
    .cropIcon svg {
      height: 16px;
      width: 16px;
    }
  }
  .cropContainer {
    background-color: #e9f4f6;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    border: 1px solid #008bad;
    border-radius: 8px;
    width: 100%;
  }
  button {
    @include tooltipButton;
  }
  .buttonsContainer {
    @include buttonsFlex;
  }
  .smallButton {
    @include tooltipSmallButton;
  }
  .mediumButton {
    @include tooltipMediumButton;
  }
  .largeButton {
    @include tooltipLargeButton;
  }
  .disabled button {
    @include tooltipDisabledButton;
  }
  .loader>div {
    @include tooltipLoader;
  }
}

.AddImageTooltip.addImageContent {
  @include storefrontTooltip;
}

.AddImageTooltip.selectImageContent {
  @include hideStorefrontTooltip;
}
