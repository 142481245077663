@use 'styles/_styleguide.scss'as *;
@use 'src/features/Storefront/StorefrontMixins.module.scss'as *;

.BrandSettingsTooltip {
  @include storefrontTooltip;

  .title {
    @include tooltipTitle;
    margin: 0 0 25px 0;
  }

  .subtitle {
    @include tooltipSubtitle;
    margin: 25px 0 0 0;

    h4 {
      @include tooltipSubtitle;
      margin: 25px 0 0 0;
    }
  }

  .link {
    text-transform: uppercase;
  }

  .switch {
    @include tooltipSwitch;
    margin: 15px 0;
  }

  button {
    @include tooltipButton;
  }

  .buttonsContainer {
    @include buttonsFlex;
  }

  .smallButton {
    @include tooltipSmallButton;
  }

  .mediumButton {
    @include tooltipMediumButton;
  }

  .disabled button {
    @include tooltipDisabledButton;
  }
}
