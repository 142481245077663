.label {
  display: block;
  font-weight: 700;
  margin: 18px 0 9px !important;
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
.loader {
  z-index: 1;
}
.required {
  color: #E7251A;
}
.wide {
  max-width: 200px;
  margin: auto;
}
.ExcelOrders :global(.content) {
  margin: 0;
  position: static;
}
.ExcelOrders :global(.ui.modal>.content) {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
.ExcelOrders :global(.tab) {
  float: none;
  height: auto;
  overflow: visible;
}
.ExcelOrders :global(.button.disabled) {
  color: #EDEDED !important;
}
.ExcelOrders label {
  color: #999999;
}
.ExcelOrders h1,
.ExcelOrders label {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
.ExcelOrders :global(.menu > .message) {
  display: block;
}
.ExcelOrders input {
  color: #323E48;
  line-height: 17px !important;
  font-size: 14px !important;
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif !important;
}
