.ProductDetailTableWrapper table {
  min-width: 100%;
}
.ProductDetailTableWrapper td {
  padding: 0 10px !important;
}
.ProductDetailTableWrapper thead > tr > td:first-child div {
  height: 100%;
}
.ProductDetailTableWrapper .orderMinInfo {
  position: absolute;
  right: 0;
  top: 10px;
  color: #40515D;
  font-family: 'Nunito Sans';
  font-size: 12px;
}
.ProductDetailTableWrapper .orderMin {
  color: #FF6756;
  font-weight: 700;
  font-size: 14px;
}
.ProductDetailTableWrapper .onlyBulkTable {
  top: -22px;
}
.ProductDetailTableWrapper .styleOrderMin {
  display: flex;
}
.ProductDetailTableWrapper .tabsContainer {
  position: relative;
  z-index: 1;
}
.ProductDetailTableWrapper .tabsContainer .tabHeader {
  width: 820px;
  height: 34px;
  margin: 20px 0 0 1px;
  padding-bottom: 0;
}
.ProductDetailTableWrapper .tabsContainer .tabHeader .tabHeaderText {
  text-align: center;
  color: #5C7284;
}
.ProductDetailTableWrapper .tabsContainer .tabHeader .tabWidth {
  width: 90px;
}
.ProductDetailTableWrapper .tabsContainer .tabContent {
  width: 860px;
  margin-left: 6px;
}
@media (min-width: 1366px) and (max-width: 1920px) {
  .ProductDetailTableWrapper .tabsContainer .tabContent {
    margin-left: 11px;
  }
}
.ProductDetailTableWrapper .bulkTabContainer {
  margin-top: 20px;
}
.ProductDetailTableWrapper .column {
  vertical-align: bottom;
  font-weight: 700;
  text-transform: capitalize;
}
.ProductDetailTableWrapper .column .doorsHeader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-weight: 700;
}
.ProductDetailTableWrapper .column .doorsHeader span > span > svg {
  height: 10px;
  width: 10px;
  margin-right: 5px;
}
.ProductDetailTableWrapper .column.commonColumns {
  width: 45px;
}
.ProductDetailTableWrapper .column .casepacksHeader {
  color: #5E599C;
}
.ProductDetailTableWrapper .firstVariantCellContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.ProductDetailTableWrapper .firstVariantCellContent .swatch svg,
.ProductDetailTableWrapper .firstVariantCellContent .checkbox svg {
  width: 20px;
}
.ProductDetailTableWrapper .firstVariantCellContent .swatch > div:empty {
  margin: 3px 5px 0 5px;
}
.ProductDetailTableWrapper .firstVariantCellContent .swatch > div:not(:empty) {
  margin: 0 5px;
}
.ProductDetailTableWrapper .firstVariantCellContent .variantName > div > span {
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.ProductDetailTableWrapper .doorsAccordion {
  font-weight: 700;
}
.ProductDetailTableWrapper .doorsAccordion .plusIcon {
  margin-right: 10px;
}
.ProductDetailTableWrapper .doorsAccordion .plusIcon svg {
  width: 11px;
  height: 11px;
}
.ProductDetailTableWrapper .doorRow {
  display: inline-block;
  width: 145px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ProductDetailTableWrapper .variantTextContainer {
  display: flex;
  align-items: center;
}
.ProductDetailTableWrapper .variantTextContainer .buttonContainer {
  display: inline-flex;
}
.ProductDetailTableWrapper .variantTextContainer .buttonContainer > div {
  margin-left: 4px;
  cursor: pointer;
}
.ProductDetailTableWrapper .variantTextContainer .buttonContainer > div svg {
  fill: #5C7284;
}
.ProductDetailTableWrapper .doorCell {
  width: 100%;
}
.ProductDetailTableWrapper .doorCell > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ProductDetailTableWrapper .doorCell .doorRow {
  width: initial;
}
