.Grid {
  display: block;
  width: 100%;
}
.Grid.container {
  padding: 0 19px;
}
@media (max-width: 1023px) {
  .Grid.container {
    width: 1024px;
  }
}
@media (min-width: 1366px) {
  .Grid.container {
    padding: 0 33px;
  }
}
@media (min-width: 1920px) {
  .Grid.container {
    padding: 0 90px;
  }
}
.Grid .Row {
  text-align: center;
  margin: 0 -5px;
}
@media (min-width: 1366px) {
  .Grid .Row {
    margin: 0 -10px;
  }
}
@media (min-width: 1920px) {
  .Grid .Row {
    margin: 0 -10px;
  }
}
.Grid .Column {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1365px) {
  .Grid .Column.column-sm-0 {
    display: none;
  }
}
@media (max-width: 1919px) and (min-width: 1366px) {
  .Grid .Column.column-md-0 {
    display: none;
  }
}
@media (min-width: 1920px) {
  .Grid .Column.column-lg-0 {
    display: none;
  }
}
.Grid .Column.column-sm-1 {
  width: 83px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-2 {
  width: 166px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-3 {
  width: 249px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-4 {
  width: 332px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-5 {
  width: 415px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-6 {
  width: 498px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-7 {
  width: 581px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-8 {
  width: 664px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-9 {
  width: 747px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-10 {
  width: 830px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-11 {
  width: 913px;
  padding: 0 5px 0;
}
.Grid .Column.column-sm-12 {
  width: 996px;
  padding: 0 5px 0;
}
@media (min-width: 1366px) {
  .Grid .Column.column-md-1 {
    width: 110px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-2 {
    width: 220px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-3 {
    width: 330px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-4 {
    width: 440px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-5 {
    width: 550px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-6 {
    width: 660px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-7 {
    width: 770px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-8 {
    width: 880px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-9 {
    width: 990px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-10 {
    width: 1100px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-11 {
    width: 1210px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-md-12 {
    width: 1320px;
    padding: 0 10px 0;
  }
}
@media (min-width: 1920px) {
  .Grid .Column.column-lg-1 {
    width: 110px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-2 {
    width: 220px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-3 {
    width: 330px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-4 {
    width: 440px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-5 {
    width: 550px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-6 {
    width: 660px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-7 {
    width: 770px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-8 {
    width: 880px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-9 {
    width: 990px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-10 {
    width: 1100px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-11 {
    width: 1210px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-12 {
    width: 1320px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-13 {
    width: 1430px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-14 {
    width: 1540px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-15 {
    width: 1650px;
    padding: 0 10px 0;
  }
  .Grid .Column.column-lg-16 {
    width: 1760px;
    padding: 0 10px 0;
  }
}
.Grid .left {
  text-align: left;
}
.Grid .center {
  text-align: center;
}
.Grid .right {
  text-align: right;
}
