// Naming convention can be found by clicking comments on the approved UX palette.
// This file is expected to help provide single source of color hex changes if design evolves our palette.
// https://jooraccess.invisionapp.com/d/#/console/17713667/381514070/comments

$primary: #5c7284;
$primary2: #40515d;
$secondary1: #cdd4da;
$secondary2: #ebedf0;
$secondary3: #ffffff;
$tertiary1: #8c9ba8;
$tertiary2: #323e48;

$red: #e7251a;
$pink: #ff6756;
$yellow: #f8c723;
$green: #4aa546;

$data1: #40515d;
$data2: #8c837b;
$data3: #b6ced0;
$data4: #914a79;
$data5: #676692;

$table1: #b6ced0;
$table2: #d1e3e3;
$table3: #e9f4f6;

// CONVENIENCE COLORS (Not necessarily in comments from InVision URL above)
$text-primary: #444444;
$white: #ffffff;
