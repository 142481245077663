.ProductDetailSizedTable {
  overflow-x: scroll;
}
.ProductDetailSizedTable ::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 8px;
}
.ProductDetailSizedTable ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.ProductDetailSizedTable .table {
  max-height: 400px;
}
.ProductDetailSizedTable .table table > thead > tr > td {
  padding-bottom: 0 !important;
}
.ProductDetailSizedTable .table table > tbody > tr > td {
  vertical-align: middle;
}
.ProductDetailSizedTable .table table > tbody > tr > td:first-child,
.ProductDetailSizedTable .table table > thead > tr > td:first-child,
.ProductDetailSizedTable .table table > tr > td:first-child {
  width: 210px;
}
.ProductDetailSizedTable .table .column {
  vertical-align: bottom;
  position: relative;
}
.ProductDetailSizedTable .table .column .casepacksHeader {
  color: #5E599C;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ProductDetailSizedTable .table .singleColumn {
  max-width: 338px;
}
.ProductDetailSizedTable .table .multipleColumns {
  max-width: 60px;
}
.ProductDetailSizedTable .table .casepackUnits {
  text-transform: none;
  font-weight: 400;
}
.ProductDetailSizedTable .table .unitsAvailable {
  font-weight: 400;
}
.ProductDetailSizedTable .table .unitsAvailable.invalid {
  color: #E7251A;
}
.ProductDetailSizedTable .table .numberInput input {
  font-size: 12px !important;
  text-align: right !important;
  height: 20px;
  width: 45px;
  padding: 2px;
  border: 1px solid #CCCCCC !important;
  background-color: #F8F8F8 !important;
}
.ProductDetailSizedTable .table .numberInput input:hover {
  border: 1px solid #40515D !important;
}
.ProductDetailSizedTable .table .numberInput input:focus {
  border-color: #40515D !important;
  background-color: rgba(173, 149, 73, 0.2) !important;
}
.ProductDetailSizedTable .table .numberInput input:focus::selection {
  background: transparent;
}
.ProductDetailSizedTable .table .numberInput input:disabled:hover {
  border: 1px solid #CCCCCC !important;
}
.ProductDetailSizedTable .table .numberInput.invalid input {
  border: 1px solid #E7251A !important;
}
.casepacksTooltipTitle {
  height: 30px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}
.casepacksTooltipTable {
  background: #323E48;
  color: #FFFFFF;
  max-width: 464px;
  padding-bottom: 10px;
  overflow: scroll;
  margin-top: 8px;
}
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table {
  margin: auto;
}
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table thead {
  border-top: 1px solid #666666;
}
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table th.casepacksTooltipCell,
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table .casepacksTooltipCell {
  padding: 0 5px 0 0;
  width: 54px;
  height: 25px;
  line-height: 25px;
  border-bottom: 1px solid #666666;
  border-top: 1px solid #666666;
  background: #323E48;
  color: #FFFFFF;
  vertical-align: middle;
  text-align: right;
  font-weight: 400;
}
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table th.casepacksTooltipCell div,
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table .casepacksTooltipCell div {
  height: 25px;
  line-height: 25px;
  vertical-align: middle;
  text-align: right;
}
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table th.casepacksTooltipCell:last-child,
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table .casepacksTooltipCell:last-child {
  border-left: 1px solid #666666;
  border-right: 1px solid #666666;
}
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table th.casepacksTooltipCell:first-child,
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table .casepacksTooltipCell:first-child,
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table th.casepacksTooltipCell:first-child div,
.casepacksTooltipTable.casepacksTooltipTable.casepacksTooltipTable table .casepacksTooltipCell:first-child div {
  border-left: 1px solid #666666;
}
