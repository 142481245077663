.Cell :global(.ui.table tr td) {
  font-weight: normal;
  color: #40515D;
  font-size: 14px;
  line-height: 18px;
}
.Cell.thickBorder {
  border-bottom: 1px solid #5C7284 !important;
}
:global(.ui.table td.extra-small.wide),
:global(.ui.table th.extra-small.wide) {
  max-width: 40px;
  min-width: 40px;
}
:global(.ui.table td.assortment-sku.wide),
:global(.ui.table th.assortment-sku.wide) {
  max-width: 43px;
  min-width: 43px;
}
:global(.ui.table td.small.wide),
:global(.ui.table th.small.wide) {
  max-width: 80px;
  min-width: 80px;
}
:global(.ui.table td.small-medium.wide),
:global(.ui.table th.small-medium.wide) {
  max-width: 93px;
  min-width: 93px;
}
:global(.ui.table td.total-order-table.wide),
:global(.ui.table th.total-order-table.wide) {
  max-width: 115px;
  min-width: 115px;
}
:global(.ui.table td.date.wide),
:global(.ui.table th.date.wide) {
  max-width: 86px;
  min-width: 86px;
}
:global(.ui.table td.indicator.wide),
:global(.ui.table th.indicator.wide) {
  padding: 0px;
  max-width: 8px;
  min-width: 8px;
}
:global(.ui.table td.extra-large.wide),
:global(.ui.table th.extra-large.wide) {
  max-width: 300px;
  min-width: 300px;
}
:global .ui.table th.centerAlign,
:global .ui.table td.centerAlign {
  text-align: center;
}
:global .ui.table th.rightAlign,
:global .ui.table td.rightAlign {
  text-align: right;
}
:global .ui.table th.bottomAlign,
:global .ui.table td.bottomAlign {
  vertical-align: bottom;
}
:global .ui.table th.verticalAlign,
:global .ui.table td.verticalAlign {
  vertical-align: middle;
}
