.dropdown:global(.ui.selection.dropdown) {
  max-width: 280px;
  margin-top: 10px;
}
.label {
  font-weight: bold;
  color: #40515D !important;
  font-size: 14px;
  line-height: 18px;
  margin: 19px 0 9px;
}
.label:first-of-type {
  margin-top: 0px;
}
.required {
  font-weight: normal;
  color: #E7251A;
}
.StartOrder :global(.content) {
  margin: 0;
  position: static;
}
.StartOrder :global(.tab) {
  float: none;
  height: auto;
  overflow: visible;
}
.StartOrder :global(.button.disabled) {
  color: #EDEDED !important;
}
.StartOrder label {
  color: #999999;
}
.StartOrder h1,
.StartOrder label {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
.StartOrder :global(.menu > .message) {
  display: block;
}
.StartOrder input {
  color: #323E48;
  line-height: 17px !important;
  font-size: 14px !important;
}
.errorLabel {
  color: #E7251A;
}
