@use 'styles/_variables.scss' as *;
@use 'styles/_colors.scss' as *;

$newBlue: #008bad;
$gray: #cdd4da;
$disabledGray: $gray;

.ProductCard {
  position: relative;
  margin-top: 9px;
  .productNameAndComments {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    .headerSection {
      width: 100%;
      align-self: end;
      .header {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .photoBreadCrumb {
    margin: 0 auto;
    height: 350px;
    width: 100%;
    max-width: 220px;

    img {
      object-fit: contain;
      width: 100%;
      max-width: 220px;
    }
  }

  .info {
    margin-top: 8px;
  }

  .badge {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.NotingContainer {
  display: flex;
  flex-direction: column;
  .notingInfo {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    .colors {
      font-size: 12px;
      font-weight: bold;
    }
  }
  .VariantsInOtherOrdersContainer {
    display: flex;
    .variantOrderIconContainer {
      display: flex;
      align-items: center;
      margin-left: 15px;
      span {
        font-size: 12px;
        margin-left: 2px;
      }
    }
  }
}

.NotingSwatchCarousel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  .NotingSwatch {
    margin-right: 12px;
  }
  .leftArrowContainer {
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
  .carouselArrow {
    cursor: pointer;
  }
}

.NotingSwatch {
  width: 24px;
  height: 24px;
  border-radius: 1px;
  position: relative;
  border: 1px solid $gray;

  .crossed {
    border: 1px solid $primary2;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    > .line {
      stroke: $primary2;
      stroke-width: 1;
    }
  }
  &.noted {
    border: 1px solid $newBlue;
  }
  &.disabled {
    border: 1px solid $disabledGray;
    .checkedIndicator {
      background: $disabledGray;
    }
  }
  &:hover {
    border: 2px solid $newBlue !important;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      border: 1px solid $disabledGray !important;
    }
  }
  &.checked {
    border: 2px solid $newBlue !important;
  }
  .checkedIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    background: $newBlue;
    position: absolute;
    top: -5px;
    right: -5px;
    > span,
    > span svg {
      width: 10px;
      height: 10px;
    }
    > span {
      position: relative;
      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.NotingSwatchAllVariant {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid $gray;
    span {
      font-family: Montserrat, sans-serif;
      font-size: 12px;
    }
  }

  .swatchImage {
    height: 24px;
    width: 24px;
    object-fit: none;
  }
}

.externalMediaIndicators {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 10px;
  }
}

.hoverLayout {
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  height: 300px;
  cursor: pointer;
  flex-direction: column;
  font-family: $fontFamily;
  font-size: 13px;
  font-weight: $semibold;
  letter-spacing: 1px;
  line-height: 14px;
  transition: all 0.1s ease;

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease;

    span {
      font-family: $fontMontserrat;
      font-size: 13px;
    }

    &.imageOverlay {
      background-color: rgba(#ffffff, 0.75);
      span {
        color: #4c636f;
      }
      &:hover {
        background-color: #4c636f;
        span {
          color: white;
        }
      }
    }
  }
  &:hover {
    opacity: 1;
  }
}
