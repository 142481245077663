:root {
  /* 
   * Current navbar is 53px tall, but old navbar was 78px.
   * PageContent component positions everything 78px from the top like if we had the
   * old navbar yet, so we cannot replace navbar height variable everywhere yet because
   * a lot of designs rely on that value.
   * If you want to position a specific page to start just below the new navbar, you
   * have the displacement variable to just replace the top value of you page container.
   */
  --old-navbar-height: 78px;
  --actual-navbar-height: 53px;
  --public-navbar-height: 98px;
  --actual-navbar-top-displacement: calc(
    var(--actual-navbar-height) - var(--old-navbar-height)
  );
  --public-navbar-top-displacement: calc(
    var(--public-navbar-height) - var(--old-navbar-height) - 2px
  );
}
