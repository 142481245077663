.GroupSelection {
  display: flex;
  position: fixed;
  left: 75px;
  top: 110px;
  cursor: pointer;
}
.GroupSelection .label {
  color: #FFFFFF;
  background-color: #5C7284;
  border-radius: 18px;
  position: absolute;
  z-index: 1000;
  height: 30px;
  white-space: nowrap;
  width: 275px;
}
.GroupSelection .label .selectedCriteria {
  margin-right: 10px;
  position: absolute;
  top: 6px;
  left: 12px;
}
.GroupSelection .label .arrow {
  position: absolute;
  right: 13px;
  top: 5px;
  margin: 0;
}
.GroupSelection .label .arrow svg {
  width: 12px;
  height: 7px;
}
.GroupSelection .label .arrow.closed {
  top: 6px;
}
.GroupSelection .label .arrow.closed svg {
  transform: rotate(180deg);
}
.GroupSelection span {
  font-family: 'Montserrat', 'Nunito Sans', Helvetica, Arial, sans-serif;
  font-size: 12px;
}
.GroupSelection .drilldown {
  border: 0;
  width: 245px;
  margin-left: 14px;
  padding: 0;
  min-height: 0;
  height: 30px;
  color: #000000;
  background-color: #00000000;
}
.GroupSelection .drilldown span > [class^='Drilldown_chevron'] {
  position: fixed;
}
.GroupSelection .drilldown .flyoutContainer > div:first-child {
  margin-top: 5px;
}
