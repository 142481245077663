.filterPanelContainer {
  background: #FFFFFF;
  height: 70px;
  margin-bottom: 20px;
  display: flex;
}
.filterPanelContainer .filterPanelRow {
  padding-left: 5px;
  z-index: 2001;
  display: flex;
}
.filterPanelContainer .filterPanelRow .currencyCol {
  display: flex;
}
.filterPanelContainer .filterPanelRow .currencyCol .currencyRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filterPanelContainer .filterPanelRow .currencyCol .currencyRow .changeButton {
  width: 20%;
}
.filterPanelContainer .filterPanelRow .currencyCol .currencyRow .changeButton :global(.ui.button) {
  text-decoration-color: #5C7284 !important;
  font-size: 11px;
  color: #5C7284 !important;
}
.filterPanelContainer .filterPanelRow .currencyCol .currencyRow .currencyCode {
  padding: 0px 4px;
}
.filterPanelContainer .filterPanelRow .horizontalFilterListContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  position: relative;
}
.filterPanelContainer .filterPanelRow .horizontalFilterListContainer .filterOptionsDropdown {
  width: 221px;
}
@media (min-width: 1024px) and (max-width: 1365px) {
  .filterPanelContainer .filterPanelRow .horizontalFilterListContainer .filterOptionsDropdown {
    width: 156px;
  }
}
.filterPanelContainer .filterPanelRow .horizontalFilterListContainer .selectedFilterDropdown {
  width: 331px;
}
@media (min-width: 1024px) and (max-width: 1365px) {
  .filterPanelContainer .filterPanelRow .horizontalFilterListContainer .selectedFilterDropdown {
    width: 316px;
  }
}
.filterPanelContainer .filterPanelRow .horizontalFilterListContainer .selectedFilterDropdown :global(.ui.dropdown) {
  max-width: none !important;
}
.filterPanelContainer .filterPanelRow .horizontalFilterListContainer .horizontalFilterList {
  position: absolute;
}
@media (min-width: 1024px) and (max-width: 1365px) {
  .filterPanelContainer .filterPanelRow .horizontalFilterListContainer .horizontalFilterList .appliedFilters {
    width: 264px;
  }
}
.datePicker {
  width: 310px;
}
.datePicker span > input {
  padding: 11px 10px 11px 13px !important;
}
