.spacedGrid > :not(:first-child) {
  margin-top: 20px;
}
.required {
  color: #E7251A;
}
.label {
  font-weight: bold;
  color: #40515D !important;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
  margin-bottom: 9px;
}
.required {
  font-weight: normal;
  color: #E7251A;
}
.modalContainer {
  margin-bottom: 40px;
}
.modalRow {
  clear: both;
  width: 100%;
}
.modalCol {
  float: left;
  margin: 10px 0;
}
.modalCol.left {
  width: 51.2%;
}
.modalCol.right {
  width: 40%;
}
.modalColCheckBox {
  margin: 0;
}
.modalColCheckBox .checkbox {
  padding: 20px 0;
}
.CreateOrderTemplateModal :global(.content) {
  margin: 0;
  position: static;
}
.CreateOrderTemplateModal :global(.tab) {
  float: none;
  height: auto;
  overflow: visible;
}
.CreateOrderTemplateModal :global(.button.disabled) {
  color: #EDEDED !important;
}
.CreateOrderTemplateModal label {
  color: #999999;
}
.CreateOrderTemplateModal h1,
.CreateOrderTemplateModal label {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
}
.CreateOrderTemplateModal :global(.menu > .message) {
  display: block;
}
.CreateOrderTemplateModal input {
  color: #323E48;
  line-height: 17px !important;
  font-size: 14px !important;
}
