.HeaderCell :global(.ui.table thead th) {
  font-weight: bold;
  color: #40515D !important;
  font-size: 14px;
  line-height: 18px;
}
.HeaderCell.sorted {
  background-color: #F8F8F8 !important;
}
.HeaderCell.withIcon {
  padding-right: 15px !important;
}
.HeaderCell.truncate {
  text-overflow: ellipsis;
}
