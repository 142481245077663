@use 'styles/_variables.scss'as *;
@use 'styles/_styleguide.scss'as *;
@use 'styles/_colors.scss'as *;

@mixin storefrontTooltip {
  width: 400px !important;
  max-width: 400px !important ;
  background-color: $white !important;
  border-radius: 15px !important;
  color: #2d3439 !important;
  &::before {
    display: none;
  }
}

@mixin hideStorefrontTooltip {
  position: static !important;
  transform: translateX(50vw) !important;
  &::before {
    display: none;
  }
}

@mixin tooltipTitle {
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: $regular;
}

@mixin tooltipSubtitle {
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $regular;
}

@mixin tooltipSwitch {
  span {
    margin-left: 8px;
    font-family: $fontFamily;
    font-size: $smallFontSize;
    font-weight: $regular;
    letter-spacing: 0.6px;
    line-height: 16px;
  }
  > span {
    margin-left: 15px;
  }
}

@mixin rowTooltipDropdown {
  margin-top: 10px;
  width: calc(100% + 2px) !important;
  min-height: 20px !important;
  padding: 5px !important;
  border-radius: 5px !important;
  border: 1px solid #8c9ba8 !important;
  i {
    top: 14px !important;
  }
  div {
    font-family: $fontFamily;
    font-size: $smallFontSize !important;
    font-weight: $regular;
  }
}

@mixin tooltipRadio {
  font-family: $fontFamily;
  font-size: $smallFontSize;
  margin: 10px;
  label {
    padding-left: 30px !important;
  }
  &:first-of-type {
    margin-left: 0;
  }
}

@mixin tooltipButton {
  font-family: $fontFamily;
  font-size: $smallFontSize;
  text-align: left;
  background-color: $white;
  border: 1px solid #2d3439;
  border-radius: 5px;
  padding: 2px 10px;
  height: 30px;
  cursor: pointer;
}

@mixin tooltipDisabledButton {
  background-color: $lightGray;
  &:hover {
    background-color: $lightGray;
  }
}

@mixin buttonsFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

@mixin tooltipSmallButton {
  width: 100px;
  margin-right: 20px;
  button {
    text-align: center;
  }
}

@mixin tooltipMediumButton {
  width: 200px;
  button {
    background-color: #323e48;
    color: $white;
    border: none;
  }
}

@mixin tooltipLargeButton {
  width: auto;
}

@mixin tooltipReplacingButton {
  padding: 0;
  border: none;
  color: #008bad;
}

@mixin tooltipDisabledButton {
  background-color: $lightGray;

    &:hover {
      background-color: $lightGray;
    }
}

@mixin widgetTooltipDropdown {
  margin: 10px 0;
  height: 40px;
  > div:first-child {
    padding: 0 !important;
    max-height: 100%;
    height: 100%;
    padding: 0;
    width: 100%;
    max-width: 100%;
    input {
      max-width: 100%;
      width: 100%;
      padding: 0;
      padding-left: 10px;
      height: 40px;
      display: table-cell;
      vertical-align: middle;
    }
    > div:first-of-type {
      height: 40px;
      display: table-cell;
      vertical-align: middle;
      padding-left: 10px;
    }
  }
}

@mixin tooltipCropper {
  width: 100%;
    >div:first-child {
      display: flex;
      align-items: center;
      >span {
        height: 21px;
        font-size: $fontSize;
        font-weight: $regular;
        margin-right: 5px;
      }
      .cropIcon svg {
        height: 16px;
        width: 16px;
      }
    }
    >div:nth-child(2) {
      background-color: #e9f4f6;
      display: flex;
      justify-content: center;
      padding: 0 20px;
      margin-top: 10px;
      border: 1px solid #008bad;
      border-radius: 8px;
    }
}

@mixin tooltipLoader {
  top: -20px;
  left: -15px;
  border-radius: 15px;
}

@mixin tabOptions {
  display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      width: 180px;
      height: 32px;
      font-family: $fontFamily;
      font-size: $fontSize;
      letter-spacing: 1px;
      line-height: 16px;
      text-align: left;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-left: 8px;
      &.active {
        background-color: #e9f4f6;
        border-bottom: 2px solid #0cc0de;
      }
    }
}