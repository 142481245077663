*, ::before, ::after {
  border-color: currentColor
}

.tw-container {
  width: 100%
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px
  }
}

.tw-fixed {
  position: fixed !important
}

.tw-absolute {
  position: absolute !important
}

.tw-relative {
  position: relative !important
}

.tw-inset-0 {
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important
}

.tw-top-0 {
  top: 0px !important
}

.tw-top-2 {
  top: 0.5rem !important
}

.tw-right-0 {
  right: 0px !important
}

.tw-right-2 {
  right: 0.5rem !important
}

.tw-left-0 {
  left: 0px !important
}

.tw-z-0 {
  z-index: 0 !important
}

.tw-z-10 {
  z-index: 10 !important
}

.tw-col-span-2 {
  grid-column: span 2 / span 2 !important
}

.tw-col-span-3 {
  grid-column: span 3 / span 3 !important
}

.tw-col-span-4 {
  grid-column: span 4 / span 4 !important
}

.tw-col-span-5 {
  grid-column: span 5 / span 5 !important
}

.tw-col-span-full {
  grid-column: 1 / -1 !important
}

.tw-m-0 {
  margin: 0px !important
}

.tw-m-2 {
  margin: 0.5rem !important
}

.tw-m-4 {
  margin: 1rem !important
}

.tw-m-auto {
  margin: auto !important
}

.tw-mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important
}

.tw-mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.tw-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important
}

.tw-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important
}

.tw-mt-0 {
  margin-top: 0px !important
}

.tw-mt-1 {
  margin-top: 0.25rem !important
}

.tw-mt-2 {
  margin-top: 0.5rem !important
}

.tw-mt-4 {
  margin-top: 1rem !important
}

.tw-mt-5 {
  margin-top: 1.25rem !important
}

.tw-mt-6 {
  margin-top: 1.5rem !important
}

.tw-mt-10 {
  margin-top: 2.5rem !important
}

.tw-mt-12 {
  margin-top: 3rem !important
}

.tw-mt-16 {
  margin-top: 4rem !important
}

.tw-mt-20 {
  margin-top: 5rem !important
}

.tw-mt-40 {
  margin-top: 10rem !important
}

.tw-mt-0\.5 {
  margin-top: 0.125rem !important
}

.tw-mt-2\.5 {
  margin-top: 0.625rem !important
}

.tw--mt-1 {
  margin-top: -0.25rem !important
}

.tw--mt-2 {
  margin-top: -0.5rem !important
}

.tw--mt-8 {
  margin-top: -2rem !important
}

.tw-mr-1 {
  margin-right: 0.25rem !important
}

.tw-mr-2 {
  margin-right: 0.5rem !important
}

.tw-mr-3 {
  margin-right: 0.75rem !important
}

.tw-mr-4 {
  margin-right: 1rem !important
}

.tw-mr-5 {
  margin-right: 1.25rem !important
}

.tw-mr-6 {
  margin-right: 1.5rem !important
}

.tw-mr-8 {
  margin-right: 2rem !important
}

.tw-mr-10 {
  margin-right: 2.5rem !important
}

.tw-mr-12 {
  margin-right: 3rem !important
}

.tw-mr-16 {
  margin-right: 4rem !important
}

.tw-mr-20 {
  margin-right: 5rem !important
}

.tw-mb-0 {
  margin-bottom: 0px !important
}

.tw-mb-2 {
  margin-bottom: 0.5rem !important
}

.tw-mb-3 {
  margin-bottom: 0.75rem !important
}

.tw-mb-4 {
  margin-bottom: 1rem !important
}

.tw-mb-5 {
  margin-bottom: 1.25rem !important
}

.tw-mb-6 {
  margin-bottom: 1.5rem !important
}

.tw-mb-8 {
  margin-bottom: 2rem !important
}

.tw-mb-12 {
  margin-bottom: 3rem !important
}

.tw-mb-16 {
  margin-bottom: 4rem !important
}

.tw-mb-20 {
  margin-bottom: 5rem !important
}

.tw--mb-6 {
  margin-bottom: -1.5rem !important
}

.tw-ml-1 {
  margin-left: 0.25rem !important
}

.tw-ml-2 {
  margin-left: 0.5rem !important
}

.tw-ml-3 {
  margin-left: 0.75rem !important
}

.tw-ml-4 {
  margin-left: 1rem !important
}

.tw-ml-6 {
  margin-left: 1.5rem !important
}

.tw-ml-2\.5 {
  margin-left: 0.625rem !important
}

.first\:tw-ml-0:first-child {
  margin-left: 0px !important
}

.last\:tw-mr-0:last-child {
  margin-right: 0px !important
}

.tw-block {
  display: block !important
}

.tw-inline-block {
  display: inline-block !important
}

.tw-flex {
  display: flex !important
}

.tw-inline-flex {
  display: inline-flex !important
}

.tw-grid {
  display: grid !important
}

.tw-hidden {
  display: none !important
}

.tw-h-8 {
  height: 2rem !important
}

.tw-h-9 {
  height: 2.25rem !important
}

.tw-h-12 {
  height: 3rem !important
}

.tw-h-16 {
  height: 4rem !important
}

.tw-h-24 {
  height: 6rem !important
}

.tw-h-28 {
  height: 7rem !important
}

.tw-h-36 {
  height: 9rem !important
}

.tw-h-40 {
  height: 10rem !important
}

.tw-h-52 {
  height: 13rem !important
}

.tw-h-56 {
  height: 14rem !important
}

.tw-h-64 {
  height: 16rem !important
}

.tw-h-80 {
  height: 20rem !important
}

.tw-h-96 {
  height: 24rem !important
}

.tw-h-240 {
  height: 60rem !important
}

.tw-h-auto {
  height: auto !important
}

.tw-h-3\/4 {
  height: 75% !important
}

.tw-h-2\/5 {
  height: 40% !important
}

.tw-h-3\/5 {
  height: 60% !important
}

.tw-h-2\/6 {
  height: 33.333333% !important
}

.tw-h-full {
  height: 100% !important
}

.tw-max-h-120 {
  max-height: 30rem !important
}

.tw-max-h-240 {
  max-height: 60rem !important
}

.tw-w-8 {
  width: 2rem !important
}

.tw-w-24 {
  width: 6rem !important
}

.tw-w-36 {
  width: 9rem !important
}

.tw-w-52 {
  width: 13rem !important
}

.tw-w-56 {
  width: 14rem !important
}

.tw-w-72 {
  width: 18rem !important
}

.tw-w-120 {
  width: 30rem !important
}

.tw-w-128 {
  width: 32rem !important
}

.tw-w-240 {
  width: 60rem !important
}

.tw-w-auto {
  width: auto !important
}

.tw-w-1\/2 {
  width: 50% !important
}

.tw-w-1\/3 {
  width: 33.333333% !important
}

.tw-w-3\/5 {
  width: 60% !important
}

.tw-w-4\/5 {
  width: 80% !important
}

.tw-w-6\/12 {
  width: 50% !important
}

.tw-w-11\/12 {
  width: 91.666667% !important
}

.tw-w-full {
  width: 100% !important
}

.tw-min-w-full {
  min-width: 100% !important
}

.tw-min-w-max {
  min-width: -webkit-max-content !important;
  min-width: max-content !important
}

.tw-max-w-xl {
  max-width: 36rem !important
}

.tw-max-w-full {
  max-width: 100% !important
}

.tw-max-w-screen-sm {
  max-width: 640px !important
}

.tw-max-w-screen-lg {
  max-width: 1024px !important
}

.tw-flex-grow {
  flex-grow: 1 !important
}

.tw-origin-top-right {
  transform-origin: top right !important
}

.tw-transform {
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.tw-rotate-180 {
  --tw-rotate: 180deg !important
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes tw-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes tw-pulse {
  50% {
    opacity: .5
  }
}

@keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.tw-cursor-pointer {
  cursor: pointer !important
}

.tw-resize-none {
  resize: none !important
}

.tw-auto-rows-auto {
  grid-auto-rows: auto !important
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.tw-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important
}

.tw-flex-row {
  flex-direction: row !important
}

.tw-flex-col {
  flex-direction: column !important
}

.tw-flex-wrap {
  flex-wrap: wrap !important
}

.tw-content-center {
  align-content: center !important
}

.tw-items-center {
  align-items: center !important
}

.tw-items-baseline {
  align-items: baseline !important
}

.tw-justify-start {
  justify-content: flex-start !important
}

.tw-justify-end {
  justify-content: flex-end !important
}

.tw-justify-center {
  justify-content: center !important
}

.tw-justify-between {
  justify-content: space-between !important
}

.tw-justify-evenly {
  justify-content: space-evenly !important
}

.tw-gap-0 {
  gap: 0px !important
}

.tw-gap-1 {
  gap: 0.25rem !important
}

.tw-gap-3 {
  gap: 0.75rem !important
}

.tw-gap-7 {
  gap: 1.75rem !important
}

.tw-gap-x-5 {
  column-gap: 1.25rem !important
}

.tw-gap-x-8 {
  column-gap: 2rem !important
}

.tw-gap-y-6 {
  row-gap: 1.5rem !important
}

.tw-gap-y-10 {
  row-gap: 2.5rem !important
}

.tw-overflow-hidden {
  overflow: hidden !important
}

.tw-overflow-visible {
  overflow: visible !important
}

.tw-overflow-scroll {
  overflow: scroll !important
}

.tw-overflow-x-auto {
  overflow-x: auto !important
}

.tw-overflow-x-hidden {
  overflow-x: hidden !important
}

.tw-overflow-y-hidden {
  overflow-y: hidden !important
}

.tw-overflow-y-scroll {
  overflow-y: scroll !important
}

.tw-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important
}

.tw-whitespace-nowrap {
  white-space: nowrap !important
}

.tw-whitespace-pre-line {
  white-space: pre-line !important
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap !important
}

.tw-break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important
}

.tw-rounded-md {
  border-radius: 0.375rem !important
}

.tw-rounded-xl {
  border-radius: 0.75rem !important
}

.tw-rounded-3xl {
  border-radius: 1.5rem !important
}

.tw-rounded-full {
  border-radius: 9999px !important
}

.tw-rounded-t-xl {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important
}

.tw-rounded-l-xl {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important
}

.tw-border {
  border-width: 1px !important
}

.tw-border-solid {
  border-style: solid !important
}

.tw-border-none {
  border-style: none !important
}

.tw-border-primary-400 {
  border-color: var(--color-primary-400) !important
}

.tw-border-primary-600 {
  border-color: var(--color-primary-600) !important
}

.tw-bg-transparent {
  background-color: transparent !important
}

.tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important
}

.tw-bg-primary-100 {
  background-color: var(--color-primary-100) !important
}

.tw-bg-primary-200 {
  background-color: var(--color-primary-200) !important
}

.tw-bg-secondary-100 {
  background-color: var(--color-secondary-100) !important
}

.tw-object-contain {
  object-fit: contain !important
}

.tw-object-cover {
  object-fit: cover !important
}

.tw-p-0 {
  padding: 0px !important
}

.tw-p-2 {
  padding: 0.5rem !important
}

.tw-p-4 {
  padding: 1rem !important
}

.tw-p-10 {
  padding: 2.5rem !important
}

.tw-p-16 {
  padding: 4rem !important
}

.tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important
}

.tw-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important
}

.tw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.tw-py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important
}

.tw-pt-1 {
  padding-top: 0.25rem !important
}

.tw-pt-2 {
  padding-top: 0.5rem !important
}

.tw-pt-5 {
  padding-top: 1.25rem !important
}

.tw-pt-8 {
  padding-top: 2rem !important
}

.tw-pt-16 {
  padding-top: 4rem !important
}

.tw-pr-12 {
  padding-right: 3rem !important
}

.tw-pr-16 {
  padding-right: 4rem !important
}

.tw-pb-0 {
  padding-bottom: 0px !important
}

.tw-pb-4 {
  padding-bottom: 1rem !important
}

.tw-pb-20 {
  padding-bottom: 5rem !important
}

.tw-pl-3 {
  padding-left: 0.75rem !important
}

.tw-pl-4 {
  padding-left: 1rem !important
}

.tw-text-left {
  text-align: left !important
}

.tw-text-center {
  text-align: center !important
}

.tw-text-right {
  text-align: right !important
}

.tw-align-middle {
  vertical-align: middle !important
}

.tw-font-display {
  font-family: Montserrat, Nunito Sans, Helvetica, Arial, sans-serif !important
}

.tw-font-nunito {
  font-family: Nunito Sans, Helvetica, Arial, sans-serif !important
}

.tw-text-xs {
  font-size: 0.75rem !important
}

.tw-text-sm {
  font-size: 0.875rem !important
}

.tw-text-base {
  font-size: 1rem !important
}

.tw-text-lg {
  font-size: 1.125rem !important
}

.tw-text-xl {
  font-size: 1.25rem !important
}

.tw-text-3xl {
  font-size: 1.875rem !important
}

.tw-text-4xl {
  font-size: 2.25rem !important
}

.tw-text-5xl {
  font-size: 3rem !important
}

.tw-text-8xl {
  font-size: 6rem !important
}

.tw-font-light {
  font-weight: 300 !important
}

.tw-font-normal {
  font-weight: 400 !important
}

.tw-font-medium {
  font-weight: 500 !important
}

.tw-font-semibold {
  font-weight: 600 !important
}

.tw-font-bold {
  font-weight: 700 !important
}

.tw-font-extrabold {
  font-weight: 800 !important
}

.tw-font-black {
  font-weight: 900 !important
}

.tw-uppercase {
  text-transform: uppercase !important
}

.tw-capitalize {
  text-transform: capitalize !important
}

.tw-leading-4 {
  line-height: 1rem !important
}

.tw-leading-5 {
  line-height: 1.25rem !important
}

.tw-leading-6 {
  line-height: 1.5rem !important
}

.tw-tracking-normal {
  letter-spacing: 0em !important
}

.tw-tracking-wide {
  letter-spacing: 0.025em !important
}

.tw-tracking-wider {
  letter-spacing: 0.05em !important
}

.tw-tracking-widest {
  letter-spacing: 0.1em !important
}

.tw-text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important
}

.tw-text-primary-300 {
  color: var(--color-primary-300) !important
}

.tw-text-primary-400 {
  color: var(--color-primary-400) !important
}

.tw-text-primary-500 {
  color: var(--color-primary-500) !important
}

.tw-text-primary-600 {
  color: var(--color-primary-600) !important
}

.tw-text-primary-700 {
  color: var(--color-primary-700) !important
}

.tw-text-primary-900 {
  color: var(--color-primary-900) !important
}

.tw-text-secondary-800 {
  color: var(--color-secondary-800) !important
}

.tw-text-neutral-600 {
  color: var(--color-neutral-600) !important
}

.tw-text-error-500 {
  color: var(--color-error-500) !important
}

.tw-text-error-700 {
  color: var(--color-error-700) !important
}

.hover\:tw-text-secondary-600:hover {
  color: var(--color-secondary-600) !important
}

.tw-underline {
  text-decoration: underline !important
}

.tw-no-underline {
  text-decoration: none !important
}

.hover\:tw-underline:hover {
  text-decoration: underline !important
}

.tw-placeholder-primary-400::placeholder {
  color: var(--color-primary-400) !important
}

.tw-opacity-0 {
  opacity: 0 !important
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

.tw-shadow-2 {
  --tw-shadow: var(--elevation-2) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-sm-dark {
  --tw-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.tw-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important
}

.tw-ring-primary-400 {
  --tw-ring-color: var(--color-primary-400) !important
}

.tw-ring-neutral-100 {
  --tw-ring-color: var(--color-neutral-100) !important
}

.tw-transition-colors {
  transition-property: background-color, border-color, color, fill, stroke !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important
}

.tw-line-clamp-2 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important
}

.tw-line-clamp-5 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 5 !important
}

@media (min-width: 640px) {
  .sm\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
  }
}

@media (min-width: 768px) {
  .md\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
  }

  .md\:tw-text-sm {
    font-size: 0.875rem !important
  }
}

@media (min-width: 1024px) {
  .lg\:tw-mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important
  }

  .lg\:tw-ml-4 {
    margin-left: 1rem !important
  }

  .lg\:tw-block {
    display: block !important
  }

  .lg\:tw-flex {
    display: flex !important
  }

  .lg\:tw-h-36 {
    height: 9rem !important
  }

  .lg\:tw-w-44 {
    width: 11rem !important
  }

  .lg\:tw-w-96 {
    width: 24rem !important
  }

  .lg\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
  }

  .lg\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
  }

  .lg\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
  }

  .lg\:tw-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important
  }

  .lg\:tw-flex-row {
    flex-direction: row !important
  }

  .lg\:tw-flex-nowrap {
    flex-wrap: nowrap !important
  }

  .lg\:tw-items-center {
    align-items: center !important
  }

  .lg\:tw-justify-center {
    justify-content: center !important
  }

  .lg\:tw-p-2 {
    padding: 0.5rem !important
  }
}

@media (min-width: 1280px) {
  .xl\:tw-ml-6 {
    margin-left: 1.5rem !important
  }

  .xl\:tw-w-52 {
    width: 13rem !important
  }

  .xl\:tw-w-120 {
    width: 30rem !important
  }

  .xl\:tw-p-0 {
    padding: 0px !important
  }
}

@media (min-width: 1536px) {
}
